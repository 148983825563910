import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { ImageSlideshow } from '../../components/ImageSlideshow/ImageSlideshow';
import { Link } from 'react-router-dom';
import logo from '../../assets/ctwnsnd.png';
import who from '../../assets/CTS-2024.jpeg';
import nick from '../../assets/nick.jpg';
import ak from '../../assets/ak.jpeg';
import willy from '../../assets/Willy.png';
import alex from '../../assets/alexImg.jpeg';
import './About.scss';

// Define background change
const bodyStyle = `
    body { 
        background-image: url('/public-images/about.jpeg'); 
        transition: background-image 1s ease-in-out;
    }
`;

// Define screen sizes
const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
    tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
    large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
    xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
};

// Page to display info about the company
export function About(props) {
    // Get the component props
    const { sideBarOpen } = props;
    const [screenSize, setScreenSize] = useState(screens);
    const [slideWidth, setSlideWidth] = useState(650);
    const [slideHeight, setSlideHeight] = useState(400);
    const [slideWidthOpen, setSlideWidthOpen] = useState(550);
    const [slideHeightOpen, setSlideHeightOpen] = useState(300);
    const [isMobile, setIsMobile] = useState(false);
    const topRef = useRef(null);

    useEffect(() => {
        const screenSizes = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tabletPort: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches,
            tabletLand: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: landscape)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1919px)").matches,
            large: window.matchMedia("all and (min-device-width: 1920px) and (max-device-width: 2559px)").matches,
            xlarge: window.matchMedia("all and (min-device-width: 2560px)").matches,
        };
        const keys = Object.keys(screenSizes);

        const prev = {...screenSizes}
        const prevKeys = Object.keys(prev);
        for (let i = 0; i < prevKeys.length; i++) {
            if (screenSizes[keys[i]] !== prev[prevKeys[i]]) {
                prev[prevKeys[i]] = screenSizes[keys[i]];
            }
        }
        setScreenSize(prev);
    }, []);

    useEffect(() => {
        if (screenSize.small) {
            setSlideWidth(300);
            setSlideHeight(200);
            setSlideWidthOpen(300);
            setSlideHeightOpen(200);
            setIsMobile(true);
        } else if (screenSize.tabletPort) {
            setSlideWidth(600);
            setSlideHeight(350);
            setSlideWidthOpen(500);
            setSlideHeightOpen(300);
            setIsMobile(false);
        } else if (screenSize.tabletLand) {
            setSlideWidth(600);
            setSlideHeight(400);
            setSlideWidthOpen(500);
            setSlideHeightOpen(300);
            setIsMobile(false);
        } else if (screenSize.large) {
            setSlideWidth(450);
            setSlideHeight(275);
            setSlideWidthOpen(650);
            setSlideHeightOpen(400);
            setIsMobile(false);
        } else if (screenSize.xlarge) {
            setSlideWidth(1100);
            setSlideHeight(700);
            setSlideWidthOpen(1000);
            setSlideHeightOpen(600);
            setIsMobile(false);
        } else {
            setSlideWidth(500);
            setSlideHeight(300);
            setSlideWidthOpen(425);
            setSlideHeightOpen(300);
            setIsMobile(false);
        }
    }, [screenSize]);

    const scrollToTopHandler = () => {
        setTimeout(() => topRef.current.scrollIntoView({ inline: 'center', behavior: 'smooth' }), 100);
    };

    return (
        <section id="about-section" style={!isMobile ? {width: sideBarOpen ? '85%' : '97%'} : {}} ref={topRef}>
            <Helmet>
                <title>Chucktown Sound | About Us</title>
                <style>{bodyStyle}</style>
            </Helmet>

            <div id="about-title-div" className="about-title">
                <div id="about-title-left">
                    <img src={logo} alt="logo.png" />
                </div>
                <div id="about-title-right">
                    <h1>We Are<br />Chucktown Sound</h1>
                    <h3>The Official Athletic Band for the College of Charleston</h3>
                </div>
            </div>

            <div id="about-wrapper-div" className="about-wrapper-div">
                <div id="about-content" className="about-content">
                    <div id="who-we-are" className="about-content-center">
                        {!screens.large && <h1 id="who-we-are-title">Who We Are</h1>}
                        <div id="who-we-are-img">
                           <img src={who} alt="chucktown-sound-1819.jpeg" />
                            <p className="caption">Chucktown Sound 2023-2024</p> 
                        </div>
                        <div>
                            {screens.large && <h1 id="who-we-are-title">Who We Are</h1>}
                            <p>
                                Chucktown Sound is the official Athletic Band for the College of Charleston. Making its debut in August 2018 as a renewed version of the existing Athletic Band, Chucktown Sound creates a unique, entertaining, and high energy environment with every performance. In addition to the new moniker, the band received brand new instruments, uniforms, and music. Enrollment nearly doubled its original size following the 2018 debut season and continues to grow each year.
                            </p>
                            <p>
                                The band performs at all Men & Women's Basketball games, Volleyball games, and various other campus events. Chucktown Sound performs as a full ensemble at all Men's Basketball games. For all other events, the band spits into two ensembles: Gold Band and Maroon Band.
                            </p>
                            <p>
                                Musical selections range from classics such as <i>Hey, Baby!</i> and <i>The Charleston</i> to modern hits like <i>Ready For It</i> by Taylor Swift and <i>Fireball</i> by Pitbull.
                            </p>
                            <h4>Chucktown Sound is a thrilling addition to any official College of Charleston event.</h4>
                        </div>
                        
                    </div>

                    <hr />

                    <div id="about-drumline">
                        <h1 id="about-drumline-title">Chucktown Drumline</h1>
                        <div id="about-drumline-content" className="about-content-split">
                            <div className="split-left">
                                <p>
                                    Making its debut in the early Spring 2020 Semester, Chucktown Drumline is the newest addition to Chucktown Sound.
                                </p>
                                <p>
                                    In addition to performing with Chucktown Sound at all Men's Basketball games & events, Chucktown Drumline also performs separately as an independent group. For example, the Drumline has been asked to perform at multiple Charleston Battery games throughout several different soccer seasons. The group also rehearses separately from Chucktown Sound twice a week for an hour and a half.
                                </p>
                            </div>
                            <div className="split-right">
                                <ImageSlideshow 
                                    path={'Drumline'} 
                                    width={sideBarOpen ? slideWidthOpen : slideWidth}
                                    height={sideBarOpen ? slideHeightOpen : slideHeight}
                                />
                            </div>
                            <p>
                                Additionally, Chucktown Drumline membership is not limited to those who are primarily percussionists, meaning members may also perform on another instrument with Chucktown Sound.
                            </p>
                            <p>
                                At this time, there is no real limit on how many people can join. Membership numbers are only limited by the amount of drums the Drumline owns, but the group is looking to grow membership numbers in the future and improve the audition process. 
                            </p>
                            <p>
                                The group is currently under the direction of <Link to="#drumline-director">Willy Fraiser</Link>, whose future plans for the group include: growing membership numbers, performing at more events (both at The College as well as private and public events around Charleston), and becoming a more established and well known organization at the college.
                            </p>
                        </div>
                    </div>

                    <div id="faqs" className="about-content-center faqs">
                        <div id="faq-content">
                            <h1>Membership FAQ's</h1>
                            <h4>Q: Do I need to be a music major to audition for Chucktown Sound?</h4>
                            <p>
                                A: No, you don't! Students of all majors may audition for Chucktown Sound.
                            </p>
                            <h4>Q: What skill level is required to join Chucktown Sound?</h4>
                            <p>
                                A: All skill levels are welcome in Chucktown Sound! We do require students to complete an auditon process in order to join the band, however, it does not determine one's acceptance into the program.
                            </p>
                            <h4>Q: Will I earn a scholarship as a member of Chucktown Sound?</h4>
                            <p>
                                A: Every member of Chucktown Sound is eligable to potentially earn a scholarship. The scholarship amount will be determined by a number of factors including number of years with the band, number of events attended, and the band's budget for the year.
                            </p>
                            <h4>Q: Do I need to play a traditional band instrument to join?</h4>
                            <p>
                                A: All instruments typical and atypical of marching/pep bands are welcome in Chucktown Sound. Examples of atypical instruments that have performed with the band are violin, banjo, and even accordion!
                            </p>
                            <h4>Q: Does Chucktown Sound count as a course credit?</h4>
                            <p>
                                A: Yes! Chucktown Sound counts as a one credit class and can be accredited multiple times!
                            </p>
                            <h4>Q: What's the difference between the Gold and Maroon bands?</h4>
                            <p>
                                ​A: The only difference between the two bands is the personnel. The band only performs as a full ensemble at all Men's Basketball games and other major events. One band is not more prestigious than the other, as both bands contain a balanced rookie-to-veteran ratio.
                            </p>
                            <h4>Q: Does Chucktown Sound travel to away games?</h4>
                            <p>
                                A: Generally, no. However, the band may travel with the team to certain tournaments such as the CAA tournament or NCAA tournament (in the event that the team advances). In the event of travel, only a select group will accompany the team.
                            </p>
                        </div>
                    </div>

                    <div id="meet-the-team">
                        <h1>Meet the Team</h1>
                        <div id="director" className="about-content-split">
                            {isMobile && (
                                <div className="split-right">
                                    <img id="director-img" src={nick} alt="nick.jpg" />
                                </div>
                            )}
                            <div className="split-left info">
                                <h2>Nick Nafpliotis</h2>
                                <h4>Director of Bands</h4>
                                <p>
                                    <a href="mailto:nnafplio@cofc.edu" className="email">
                                        nnafplio@cofc.edu
                                    </a>
                                </p>
                                <p>Chucktown Sound is currently under the direction of Nick Nafpliotis.</p>
                                <p>
                                    Nick is a graduate of the University of Kentucky and has lived/taught in the Charleston area for 20 years. After 18 years directing the award-winning Laing Middle School Band program, he took a job as the Lead Teacher for the Charleston County School District’s Visual and Performing Arts Department. Nick has also been on the Visual and Music staff for bands across the southeast, including the Wando High School and Walton High School marching bands.
                                </p>
                                <p>
                                    Nick became the Director of Bands for Chucktown Sound in the Fall 2023 Semester. He is excited to work with such a great group of student musicians who make TD Arena one of college basketball’s most exciting and intimidating atmospheres.
                                </p>
                            </div>
                            {!isMobile && (
                                <div className="split-right">
                                    <img id="director-img" src={nick} alt="nick.jpg" />
                                </div>
                            )}
                        </div>

                        <hr />

                        <div id="instructor" className="about-content-split">
                            <div className="split-left">
                                <img id="instructor-img" src={ak} alt="ak.jpeg" />
                            </div>
                            <div className="split-right info">
                                <h2>Dr. Nisan Ak</h2>
                                <h4>Instructor of Record</h4>
                                <p>
                                    <a href="mailto:akn@cofc.edu" className="email">
                                        akn@cofc.edu
                                    </a>
                                </p>
                                <p>
                                    Selected as one of the thirty most inspiring people under the age of thirty <a href="https://www.forbes.com/30-under-30" target="_blank" rel="noreferrer">(30 Under 30)</a> by Forbes Turkey in 2019, Dr. Nisan Ak is a rapidly rising conductor from Istanbul who has directed orchestras on four different continents. Besides her career in conducting, Nisan is a public advocate for the accessibility of classical music for all and women’s leadership in the workspace. She creates educational outreach videos in both English and Turkish and gives speeches about women’s visibility and leadership.
                                </p>
                                <p>
                                    Dr. Ak holds a master’s degree from the City University of New York and a doctorate from the University of South Carolina, both in conducting. She is currently the director of the College of Charleston Wind Ensemble and Bruch Chamber Orchestra. She is thrilled to work with CofC’s student musicians who make the Chucktown Sound one of the very best organizations on campus.
                                </p>
                            </div>
                        </div>

                        <hr />

                        <div id="drumline-director" className="about-content-split">
                            {isMobile && (
                                <div className="split-right">
                                    <img id="dl-director-img" src={willy} alt="Willy.png" />
                                </div>
                            )}
                            <div className="split-left info">
                                <h2>Willy Fraiser</h2>
                                <h4>Drumline Director</h4>
                                <p>
                                    <a href="mailto:willydrummerkid@gmail.com" className="email">
                                        willydrummerkid@gmail.com
                                    </a>
                                </p>
                                <p>
                                    William “Willy” Frasier was born and raised in Charleston, South Carolina and has been drumming for 22 years. During that time, he has studied and trained in multiple styles of percussion, from Corps-style marching to Latin Jazz. He also plays piano and produces/composes his own music. If you live in the Charleston area, then you've likely seen him perform with the Charleston Riverdogs, at the Charleston Farmers Market, in Marion Square, or on America’s Got Talent. He is also a percussion instructor with the Lucy Beckham High School Marching Band in Mount Pleasant.
                                </p>
                                <p>
                                    As a former member of the Dancing on Air crew and a partner of the Poor Rich Kollective, Willy has also trained for over a decade in various styles of dance. His experience led him to create a new style called WÆ-work, a marching drum and hip-hop dance fusion that earned his nickname “The Sword” by dance/percussion artist Malik DOPE. After several years as Chucktown Sound's lead set player, Willy is very excited to return with the organization as an instructor.
                                </p>
                            </div>
                            {!isMobile && (
                                <div className="split-right">
                                    <img id="dl-director-img" src={willy} alt="Willy.png" />
                                </div>
                            )}
                        </div>

                        <hr />

                        <div id="webmaster" className="about-content-split">
                            <div className="split-left">
                                <img id="webmaster-img" src={alex} alt="alexImg.jpg" />
                            </div>
                            <div className="split-right info">
                                <h2>Alex Bailey</h2>
                                <h4>Associate Director & Webmaster</h4>
                                <p>
                                    <a href="mailto:bailey.alex1999@gmail.com" className="email">
                                        bailey.alex1999@gmail.com
                                    </a>
                                </p>
                                <p>
                                    Alex Bailey is a graduate of the College of Charleston (Class of 2022). He has a Bachelor’s Degree in Computer Science and is currently working full-time as a software developer for <a href="https://www.boozallen.com/" target="_blank" rel="noreferrer">Booz Allen Hamilton</a>. Alex has been involved with Chucktown Sound since its creation in 2018. He has been managing the band's website and social media since 2019. The site was originally built using a simple drag-and-drop site and was purely informational. However, in 2021, Alex rebuilt the website from scratch using <a href="https://react.dev/" target="_blank" rel="noreferrer">React</a> and <a href="https://firebase.google.com/" target="_blank" rel="noreferrer">Firebase</a>. The site also recieved a complete style redesign in 2024.
                                </p>
                                <p>
                                    The website is now used as an internal web tool for the band as well as a public informational site. In addition to his computer science skills, Alex is also an outstanding musician. His primary instrument is tuba, but he is also well practiced in bass guitar and bass trombone. He is very excited for his continued involvement with the program.
                                </p>
                            </div>

                            {isMobile && (
                                <button id="scroll-button" className="main-button" onClick={scrollToTopHandler}>Back to Top</button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
